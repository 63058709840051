export enum ENVs {
  'production' = 'production',
  'development' = 'development',
}

const NAME = 'Effecto';
const ENV =
  process.env.NODE_ENV === 'production' ? ENVs.production : ENVs.development;
const GTM_TAG = process.env.REACT_APP_GTM_TAG || 'GTM-TC6M6QN';
const API_TOKEN =
  process.env.REACT_APP_API_TOKEN || '427eea20-0d9e-478c-9c96-37b8f95c80fd';
const BASE_URL =
  process.env.REACT_APP_API_URL ||
  'https://wellness-a-team-effecto-funnel-api-sandbox.cl2.kilo.live/api/';
const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS || '';
const FIREBASE_REGISTER_URL =
  process.env.REACT_APP_FIREBASE_REGISTER_URL ||
  'https://us-central1-lasting-change-app-staging.cloudfunctions.net/register';

const FIREBASE_SHIP_BOOK =
  process.env.REACT_APP_SHIP_BOOK ||
  'https://us-central1-lasting-change-app-staging.cloudfunctions.net/shipBook';
const PATIENT_APP_BASE_URL = process.env.REACT_APP_PATIENT_APP_BASE_URL || '';
const HYROS_TOKEN = process.env.REACT_APP_HYROS_TOKEN || '';
const FIREBASE_MAILING =
  process.env.REACT_APP_FIREBASE_MAILING ||
  'https://us-central1-panda-routine-staging.cloudfunctions.net/sendEmail';
const GENERATE_EBOOK =
  process.env.REACT_APP_FIREBASE_GENERATE_EBOOK ||
  'https://us-central1-lasting-change-app-staging.cloudfunctions.net/eBookGeneration';

const REACT_APP_FIREBASE_USER_HAS_PAYED_URL =
  process.env.REACT_APP_FIREBASE_USER_HAS_PAYED_URL ||
  'https://us-central1-lasting-change-app-staging.cloudfunctions.net/userHasPaid';

const REACT_APP_FIREBASE_USER_HAS_PAID_URL_WY =
  process.env.REACT_APP_FIREBASE_USER_HAS_PAID_URL_WY ||
  'https://us-central1-walking-yoga-app-staging.cloudfunctions.net/userHasPaid';

const KLAVIYO_KEY = process.env.REACT_APP_KLAVYIO_KEY;

const TEMP_LC_KEY =
  process.env.REACT_TEMP_LC_KEY || '1cfd4c69-214a-4ca2-b4f8-f462881ba015';

const GOOGLE_LOCATION_KEY =
  process.env.REACT_GOOGLE_LOCATION_KEY ||
  'AIzaSyBV7kkqQFnnHoHdM7MxEI4Qmg-ncRSwGVU';

const FACEBOOK_PIXEL_ID = process.env.GATSBY_FACEBOOK_PIXEL_ID;

const FACEBOOK_API_ACCESS_TOKEN = process.env.GATSBY_FACEBOOK_API_ACCESS_TOKEN;

const FACEBOOK_API_VERSION = process.env.GATSBY_FACEBOOK_API_VERSION;

const TRACKING_ENABLED = true;

const CLOUD_FUNCTION_URL = process.env.FIREBASE_CLOUD_FUNCTION_URL;

const WY_REGISTER_URL =
  process.env.FIREBASE_WY_REGISTER_URL ||
  'https://us-central1-walking-yoga-app-staging.cloudfunctions.net/registerInWeb';

const CLOUD_FUNCTION_URL_SC = process.env.FIREBASE_CLOUD_URL_SC;

const CLOUD_FUNCTION_URL_TT = process.env.FIREBASE_CLOUD_CAPI_URL_TT;
//het
const configs = {
  [ENVs.development]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
    FIREBASE_REGISTER_URL,
    PATIENT_APP_BASE_URL,
    HYROS_TOKEN,
    FIREBASE_MAILING,
    REACT_APP_FIREBASE_USER_HAS_PAYED_URL,
    GENERATE_EBOOK,
    FIREBASE_SHIP_BOOK,
    KLAVIYO_KEY,
    TEMP_LC_KEY,
    GOOGLE_LOCATION_KEY,
    FACEBOOK_PIXEL_ID,
    FACEBOOK_API_ACCESS_TOKEN,
    FACEBOOK_API_VERSION,
    TRACKING_ENABLED,
    CLOUD_FUNCTION_URL,
    WY_REGISTER_URL,
    REACT_APP_FIREBASE_USER_HAS_PAID_URL_WY,
    CLOUD_FUNCTION_URL_SC,
    CLOUD_FUNCTION_URL_TT,
  },
  [ENVs.production]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
    FIREBASE_REGISTER_URL,
    PATIENT_APP_BASE_URL,
    HYROS_TOKEN,
    FIREBASE_MAILING,
    REACT_APP_FIREBASE_USER_HAS_PAYED_URL,
    GENERATE_EBOOK,
    FIREBASE_SHIP_BOOK,
    KLAVIYO_KEY,
    TEMP_LC_KEY,
    GOOGLE_LOCATION_KEY,
    FACEBOOK_PIXEL_ID,
    FACEBOOK_API_ACCESS_TOKEN,
    FACEBOOK_API_VERSION,
    TRACKING_ENABLED,
    CLOUD_FUNCTION_URL,
    WY_REGISTER_URL,
    REACT_APP_FIREBASE_USER_HAS_PAID_URL_WY,
    CLOUD_FUNCTION_URL_SC,
    CLOUD_FUNCTION_URL_TT,
  },
};

export const config = configs[ENV];
