export { wrapRootElement } from './src';

export const onClientEntry = () => {
    if (typeof window !== 'undefined') {

        const getCookie = name => document.cookie
                .split('; ')
                .find(row => row.startsWith(name + '='))
                ?.split('=')[1];

        const axwrtValue = getCookie('_axwrt');

        if (axwrtValue) {

            document.cookie = `axwrt=${axwrtValue}; 
                path=/; 
                max-age=${365 * 24 * 60 * 60}; 
                domain=.thelastingchange.com
                secure; 
                samesite=lax`;
        } 
    }
};
